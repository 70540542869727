import axios from "axios";
import { getUserToken } from "@/plugins/localStorageUtils"; // 从缓存工具文件中导入获取 token 的方法

const base = process.env.VUE_APP_BASE_API;

const axiosInstance = axios.create({
  baseURL: base,
  headers: {
    'Content-Type': 'application/json'
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // 从 localStorage 中获取 token
    const token = getUserToken();
    if (token!==undefined) {
      config.headers.Authorization =token;
    }
    return config;
  },
  (error) => {
    console.error('请求错误', error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      console.error('响应错误状态码:', error.response.status);
      if (error.response.status === 404) {
        console.error('资源未找到 (404)');
      }
    } else {
      console.error('请求错误:', error.message);
    }
    return Promise.reject(error);
  }
);

export const getRequest = (url, params) => {
  console.log('GET请求 URL:', url, '参数:', params);
  return axiosInstance({
    method: 'get',
    url: url,
    params: params,
  });
};

export const postRequest = (url, data) => {
  console.log('POST请求 URL:', url, '数据:', data);
  return axiosInstance({
    method: 'post',
    url: url,
    data: data,
  });
};
