import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import CheckStrUtil from './utils/CheckStrUtil';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import localStoragePlugin from './plugins/localStoragePlugin';
import { getUserData, getUserToken, setUserData, setUserToken, clearUserData } from './plugins/localStorageUtils';
import {getRequest,postRequest} from "@/api/api";
Vue.prototype.getRequest = getRequest;

Vue.prototype.postRequest = postRequest;
Vue.config.productionTip = false
Vue.use(ElementUI);
// 使用 localStorage 插件
Vue.use(localStoragePlugin);
// 在 Vue 原型链上挂载方法，使其在所有组件中可用
Vue.prototype.$getUserData = getUserData;
Vue.prototype.$getUserToken = getUserToken;
Vue.prototype.$setUserData = setUserData;
Vue.prototype.$setUserToken = setUserToken;
Vue.prototype.$clearUserData = clearUserData;
Vue.prototype.CheckStrUtil = CheckStrUtil;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
