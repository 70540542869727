// src/utils/CheckStrUtil.js
export default {
    checkString(str) {
        const regex = /^[\u4e00-\u9fa5a-zA-Z]+$/;
        return regex.test(str);
    },
    checkDate(str) {
        // 正则表达式匹配日期格式 YYYY-MM-DD
        const regex = /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
        if (!regex.test(str)) {
            return false;
        }
        // 进一步检查日期是否合法
        const [year, month, day] = str.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        return date.getFullYear() === year && 
               date.getMonth() === month - 1 && 
               date.getDate() === day;
    },
    checkID(str) {
        // 正则表达式匹配十八位且只能包含数字和大写字母X
        const regex = /^\d{17}(\d|X)$/;
        return regex.test(str);
    },
    checkPhoneNumber(str) {
        // 正则表达式匹配十一位且第一个字符为1
        const regex = /^1\d{10}$/;
        return regex.test(str);
    },
};
