// localStorageUtils.js

// 存储用户数据到 localStorage
export function setUserData(userData) {
    localStorage.setItem('user-data', JSON.stringify(userData));
  }
  
  // 获取用户数据从 localStorage
  export function getUserData() {
    const storedUserData = localStorage.getItem('user-data');
    return storedUserData ? JSON.parse(storedUserData) : null;
  }
  
  // 存储用户 token 到 localStorage
  export function setUserToken(token) {
    localStorage.setItem('user-token', token);
  }
  
  // 获取用户 token 从 localStorage
  export function getUserToken() {
    return localStorage.getItem('user-token');
  }
  
  // 清除用户数据和 token 从 localStorage
  export function clearUserData() {
    localStorage.removeItem('user-data');
    localStorage.removeItem('user-token');
  }
  