// plugins/localStoragePlugin.js

// 定义插件对象
const localStoragePlugin = {};

// 插件的安装方法
localStoragePlugin.install = function (Vue, options) {
  // 添加一个 $localStorage 对象到 Vue 原型上
  Vue.prototype.$localStorage = {
    // 设置 localStorage 中的值
    set(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    // 获取 localStorage 中的值
    get(key) {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    },
    // 移除 localStorage 中的值
    remove(key) {
      localStorage.removeItem(key);
    },
  };
};

export default localStoragePlugin;
